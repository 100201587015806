import { applyMiddleware, compose, createStore, Store, Unsubscribe } from 'redux';
import { createBrowserHistory, History } from 'history';
import { LocationChangeAction, routerMiddleware } from 'connected-react-router';
import ControllerMain from '../controllers/ControllerMain';
import * as React from 'react';
import { Provider } from 'react-redux';
import ReducerRouter, { RouterActions } from './reducers/ReducerRouter';
import { MyReducer } from './MyReducer';
import RouterView, { RouterViewProps } from '../views/router/RouterView';
import { LoginAction } from './reducers/ReducerLogin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ResetPasswordActions } from './reducers/ReducerResetPassword';
import { ResetPasswordBackActions } from './reducers/ReducerResetPasswordBack';
import { WaitingActions } from './reducers/ReducerWaiting';


export type StoreAction =
    LocationChangeAction
    | ResetPasswordBackActions
    | ResetPasswordActions
    | RouterActions
    | WaitingActions
    | LoginAction;

export default class StoreManager {

    private readonly controllerMain: ControllerMain;
    private readonly store: Store;
    private readonly history: History;
    private readonly initialState: Readonly<RouterViewProps>;

    constructor(controllerMain: ControllerMain) {

        this.controllerMain = controllerMain;

        const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        this.history = createBrowserHistory();

        const rootReducer: MyReducer<RouterViewProps, StoreAction> = this.getStoreReducer();

        const reducerFct = (state: RouterViewProps | undefined, action: StoreAction) => rootReducer.reduce(state, action);

        this.initialState = rootReducer.getInitialState();

        this.store = createStore<RouterViewProps, StoreAction, any, any>(
            reducerFct,
            this.initialState,
            composeEnhancer(
                applyMiddleware(
                    routerMiddleware(this.history),
                ),
            ),
        );
    }

    dispatch<T extends StoreAction = StoreAction>(action: T): T {
        return this.store.dispatch(action);
    }

    redirect(path: string): void {
        this.history.replace(path);
    }

    getCurrentPage() {
        return this.history.location.pathname;
    }

    generateView(): React.ReactElement<RouterViewProps> {

        // const state = this.store.getState();

        const subscribe = () => {
            const state = this.store.getState();
            if (this.controllerMain.view)
                this.controllerMain.view.setState(state);
        };

        let unsubscribe: Unsubscribe;

        const ref = (app: RouterView | null) => {
            if (app) {
                this.controllerMain.view = app;

                unsubscribe = this.store.subscribe(subscribe);
            } else {
                unsubscribe();
            }
        };

        return <Provider store={ this.store }>

            <RouterView { ...this.store.getState() } ref={ ref }/>
            <ToastContainer
                    position="bottom-right"
                    autoClose={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    // pauseOnVisibilityChange
                    draggable 
                />

        </Provider>;

    }

    private getStoreReducer(): MyReducer<RouterViewProps, StoreAction> {

        return new ReducerRouter(
            this.controllerMain,
            (action: StoreAction) => this.dispatch<any>(action),
            this.history,
        );
    }

}
