import { MyReducer } from "../MyReducer";
import { StoreAction } from "../StoreManager";
import { Action } from "redux";
import { ParamPassReset } from "../../server/recepters/ReceptersUser";
import { WaitingProps } from "../../views/waiting/WaitingView";

export enum WaitingActionsNames  {
	ASK_DATA="ASK_DATA",
	ERROR_RECONNECT="ERROR_RECONNECT",
	DATA_HERE="DATA_HERE",
	UPLOAD_FILE="UPLOAD_FILE",
	RECEIVING_FILES="RECEIVING_FILES",
	FILE_RECEIVED="FILE_RECEIVED",
	NO_MORE_ERROR="NO_MORE_ERROR"
}

export enum Files {
	FILE1="dataset.csv",
	FILE2="practice.csv",
	FILE3="kpi.csv",
	FILE_USERS="base_utilisateur.csv",
    FILE_PROPERTIES="portfolio.json"
}

export interface ActionAskData extends Action<"ASK_DATA">, ParamPassReset {
}
export interface ActionRECO extends Action<"ERROR_RECONNECT"> {}
export interface ActionDataHere extends Action<"DATA_HERE">{
	dataset: any[];
	aggregated: any[];
}

export interface ActionUploadFile extends Action<"UPLOAD_FILE"> {
	fileToUpload: Files;
	file: File;
	onSuccess: (lines?: number) => void;
	onError: () => void;
}

export interface ActionReceivingFiles extends Action<"RECEIVING_FILES"> {
	file_nb: number;
	file_total: number;
	file_name: string;
	file_data: any[];
	user_role: string;
}

export interface ActionFileReceived extends Action<"FILE_RECEIVED"> {
	line_number: {
		file: string;
		nbLines: number
	};
}

export interface ActionNoMoreError extends Action<"NO_MORE_ERROR"> {
	headerError?: boolean
	dataError?: boolean;
}

export type WaitingActions = ActionAskData | ActionNoMoreError | ActionRECO | ActionDataHere | ActionUploadFile | ActionReceivingFiles | ActionFileReceived;


export default class ReducerWaiting extends MyReducer<WaitingProps, StoreAction> {
	getInitialState(): WaitingProps {
		return {
			name: "WAITING",
		}
	}

    protected onReduce(state: WaitingProps | undefined, action: StoreAction): WaitingProps {
		if (!state)
			state = this.getInitialState();

		switch(action.type) {
			
			case WaitingActionsNames.ASK_DATA:
				this.controllerMain.controllerUser.askData();
				state.isAdmin = this.controllerMain.isAdmin;
				break;

			case WaitingActionsNames.ERROR_RECONNECT:
				state.reconnect = true;
				break;
			
			case WaitingActionsNames.DATA_HERE:
				state.dataToSend = {dataset: action.dataset, aggregated: action.aggregated};
				state.dataReady = true;
				break;
				case WaitingActionsNames.UPLOAD_FILE:
					this.uploadFile(action);
					break;
			case WaitingActionsNames.RECEIVING_FILES:
				// console.log('FILES RECEIVING', action)
				if (state.receivingFiles === undefined) {
					state.receivingFiles = {
						nbOfFiles: action.file_total,
						files: [
							{
								file_name: action.file_name,
								file_data: action.file_data
							}
						]
					}
				}
				else {
					state.receivingFiles.files.push({
						file_name: action.file_name,
						file_data: action.file_data
					})

					if (action.file_nb >= state.receivingFiles.nbOfFiles) {
						state.userRole = action.user_role;
						state.dataToSend = state.receivingFiles.files.map(f => {return {file: f.file_name, data: f.file_data}});
						state.dataReady = true;
					}
				}
				break;
			case WaitingActionsNames.FILE_RECEIVED:
				
				//-1 = header incorrect
				if (action.line_number.nbLines === -1) {
					state.errorOnHeader = true;
				}
				//-2 = user file data incorrect
				else if (action.line_number.nbLines === -2) {
					state.errorOnData = true;
				}
				else {
					state.errorOnHeader = false;
					state.errorOnData = false;
				}

				if (state.fileReceivedOnServer)
					state.fileReceivedOnServer.push(action.line_number)
				else 
					state.fileReceivedOnServer = [action.line_number]
				break;
			case WaitingActionsNames.NO_MORE_ERROR:
				if (action.headerError)
					state.errorOnHeader = false;
				if (action.dataError)
					state.errorOnData = false;
				state.fileReceivedOnServer = state.fileReceivedOnServer?.filter(f => f.nbLines > 0)
				break;
		}
		
		
		
		
		return {
			...state
		}
	}

	async uploadFile(action: ActionUploadFile) {

		this.controllerMain
			.controllerFile
			.uploadFile(action.file, action.fileToUpload, action.onSuccess, action.onError)
			.then((ret) => {
				if (ret && ret.linesNumber){
					this.dispatch({
                        type: WaitingActionsNames.FILE_RECEIVED,
						line_number: {
							file: action.fileToUpload,
							nbLines: ret.linesNumber
						}
                    })
				}
			})

	}

}