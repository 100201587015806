import * as React from "react";
import './loadpage.css'
const classNames = require("classnames");

export interface LoadPageProps {
    hide?: boolean;
    absolute?: boolean;
    message?: string;
    sub?: string;
}

export interface LoadPageState {

}

export default class LoadPage extends React.Component<LoadPageProps, LoadPageState> {

    constructor(props: LoadPageProps) {
        super(props);
    }

    render(): JSX.Element {
        const {hide, absolute, message, sub} = this.props;

        return <div className={classNames('load-page', {
            hide2: hide,
            absolute: absolute
        })}>

            <div className={'icon-fb'}>
                <div className={'ifb-1'}/>
                <div className={'ifb-2'}/>
                <div className={'ifb-3'}/>
                <div className={'ifb-4'}/>
                <div className={'ifb-5'}/>
            </div>

            <div className={'message'}>
                {
                    message
                        ? message.split('\n').map((m, i) => <div key={i}>{m}</div>)
                        : null
                }
            </div>

            <div className={'sub-message'}>
                {
                    sub
                        ? sub.split('\n').map((s, i) => <div key={i}>{s}</div>)
                        : null
                }
            </div>

        </div>;
    }

}