import {MyReducer} from '../MyReducer';
import {StoreAction} from '../StoreManager';
import {LoginProps} from "../../views/login/LoginView";
import {Action} from "redux";
import {ErrorService} from "../../services/ErrorService";
import { WaitingViewRoute } from '../../views/waiting/WaitingView';


export enum LoginActions {
    LOGIN_RELOAD= "LOGIN_RELOAD",
    LOGIN_CHECK_USER_AND_PASSWORD="LOGIN_CHECK_USER_AND_PASSWORD",
    LOGIN_LOGOUT="LOGIN_LOGOUT",
    LOGIN_END="LOGIN_END"
}



export interface ActionReloadLogin extends Action<'LOGIN_RELOAD'> {
    noMorePasswordWrong?: boolean;
    noMoreEmailNotVerified?:boolean;
    noMoreErrors?:boolean;
    errorCredentials?: boolean;
}

export interface ActionCheckLogin extends Action<'LOGIN_CHECK_USER_AND_PASSWORD'> {
    email: string;
    password: string;
} 

export interface ActionLogout extends Action<'LOGIN_LOGOUT'> {}


export interface ActionLoginEnd extends Action<"LOGIN_END">{
    loginOK: boolean;
}


export type LoginAction = ActionReloadLogin
    | ActionCheckLogin
    | ActionLogout
    | ActionLoginEnd
    // | ActionCheckLoginInvited;
    // | ActionAskForDispatcher;


export default class ReducerLogin extends MyReducer<LoginProps, StoreAction> {

    protected onReduce(state: LoginProps | undefined, action: StoreAction): LoginProps {

        if (!state) {
            state = this.getInitialState();
        }

        switch (action.type) {
            case LoginActions.LOGIN_RELOAD:
                if (action.noMorePasswordWrong)
                    state.errorCredentials = false;
                if (action.noMoreErrors) {
                    state.errorCredentials = false;
                }
                if (action.errorCredentials)
                    state.errorCredentials = true;
                break;

            case LoginActions.LOGIN_CHECK_USER_AND_PASSWORD:
                this.login(action, state);
                break;
            
            case LoginActions.LOGIN_END:
                state.redirect = WaitingViewRoute.getPath();
                
                break;
            
        }

        return {
            ...state
        };
    }

    getInitialState(): LoginProps {
        return {
            name: "LOGIN",
            ...ErrorService.getInitErrorState(),
            errorCredentials: false
        };
    }


    // ****************************************************************************************************
    // ******************************************** Functions *********************************************
    // ****************************************************************************************************
    private async login(action: ActionCheckLogin, state: LoginProps) {
        ErrorService.resetErrorState(state);
        if (!this.controllerMain.serverManagerUI)
            await this.controllerMain.serverPromise;
        this.controllerMain
            .controllerUser
            .login(action.email, action.password)
            .then((data) => {
                if (data && data.token) {
                    const cookie = this.controllerMain.createUserCookie(data.token.toString(), data.expirationDate, data.email);
                    this.controllerMain.userMetadata = cookie;
                    this.controllerMain.isAdmin = data.email === "Edinstitut";
                    this.dispatch({
                        type: LoginActions.LOGIN_END,
                        loginOK: true
                    })
                }
            })
            .catch((e) => {
                this.dispatch({
                    type: LoginActions.LOGIN_RELOAD,
                    errorCredentials: true
                })
            })
    }
}


export function getInitialUserProperties(){
    return {
        userFirstName: '',
        userName: '',
        login: '',
        isLogged: false
    }
}
