import { SocketLike } from "../ServerInterfaces";
import ControllerMain from '../../controllers/ControllerMain';
import ENV from "../../env";

export interface PaquetReceive<R> {
    success: boolean;
    data: R;
    code?: number;
}

export abstract class Recepter<R, S> {

    public readonly path: string;
    protected readonly io: SocketLike;
    private resolve!: (data: R) => void;
    private reject!: (code: number) => void;
    protected controllerMain!: ControllerMain;

    constructor(path: string, io: SocketLike) {
        this.path = path;
        this.io = io;
        this.resetPromise();
    }

    // protected abstract onSuccess(data: R): void;
    //
    // protected abstract onFail(code: number): void;

    send(paquet: S): Promise<R> {
        // const newPath = this.getQuery(this.path, "send");
        return new Promise<R>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            this.io.emit(this.path, paquet);
        });
    }

    on(p: PaquetReceive<R>): void {
        // console.log('server ->', this.path, p);
        if (p.success) {
            // this.onSuccess(p.data);
            this.resolve(p.data);
        } else {
            // this.onFail(p.code as number);
            this.reject(p.code as number);
            if (ENV === "development")
                console.error("DEBUG\nErreur reception serveur\n" + this.path + "\nCode " + p.code + "\n" + JSON.stringify(p));
        }
        this.resetPromise();
    }

    private resetPromise() {
        this.resolve = d => null;
        this.reject = c => null;
    }

    public setController(ctler: ControllerMain){
        this.controllerMain = ctler;
    }
}
