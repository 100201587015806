import * as React from 'react';
import { RouterState, ConnectedRouter } from 'connected-react-router';
import { PageProps } from '../Page';
import { Suspense, lazy } from 'react';
import LoadPage from '../../components/load/LoadPage';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import LoginView, { LoginRoute, LoginProps } from '../login/LoginView';
import ErrorView from '../errors/ErrorView';
import { History } from 'history';

import './router.css';
import ViewResetPasswordAsk, { ResetPasswordAskRoute, RPassAskProps } from '../resetPasswordAsk/ViewResetPasswordAsk';
import ViewResetPassword, { ResetPassProps, ResetPasswordRoute } from '../resetPassword/ViewResetPassword';
import WaitingView, { WaitingProps, WaitingViewRoute } from '../waiting/WaitingView';
import LoginViaURLView, { LoginViaURLProps, LoginViaURLRoute } from '../login/loginURLs/LoginViaUrl';
export interface RouterViewProps {

    router: RouterState;

    history: History;

    pageProps: PageProps | {};

}

export interface RouterViewState {

}

export default class RouterView extends React.PureComponent<RouterViewProps, RouterViewState> {

    render() {

        const { history, pageProps } = this.props;

        return <ConnectedRouter history={ history }>
            <Suspense fallback={ <LoadPage/> }>
                <div id={ 'router' }>
                    <Switch>
                        <Route
                            exact path={ LoginRoute.getPath() } render={ (r: RouteComponentProps<any>) => {
                                return <LoginView { ...r } { ...pageProps as LoginProps } />;
                            } }
                        />

                        <Route exact path={ LoginViaURLRoute.getPath() } render={ (r: RouteComponentProps<any>) => {
                                return <LoginViaURLView { ...r } { ...pageProps as LoginViaURLProps } />;
                            } }
                        />

                        <Route exact path={ResetPasswordAskRoute.getPath()} render={(r: RouteComponentProps<any>) => {
                            return <ViewResetPasswordAsk {...r} {...pageProps as RPassAskProps} />;
                        }} />

                        <Route exact path={ResetPasswordRoute.getPath()} render={(r: RouteComponentProps<any>) => {
                            return <ViewResetPassword {...r} {...pageProps as ResetPassProps} />;
                        }} />

                        <Route exact path={WaitingViewRoute.getPath()} render={(r: RouteComponentProps<any>) => {
                            return <WaitingView {...r} {...pageProps as WaitingProps} />
                        }} />

                        <Redirect exact from={ '/' } to={ LoginRoute.getPath() }/>

                        <Route component={ ErrorView }/>
                    </Switch>
                </div>
            </Suspense>
        </ConnectedRouter>;

    }

}
