import { SocketLike } from '../ServerInterfaces';
import { Recepter } from './Recepter';

export interface UserLoginReceive {
    token: string;
    expirationDate: Date;
	email: string;
}

export interface UserLoginSend {
    email: string;
    password: string;
}

export class RecepterUserLogin extends Recepter<UserLoginReceive, UserLoginSend> {

    constructor(io: SocketLike) {
        super("user/connexion", io);
    }
}


export interface UserLogoutReceive {}

export interface UserLogoutSend {
    token: string;
}

export class RecepterUserLogout extends Recepter<UserLogoutReceive, UserLogoutSend> {

    constructor(io: SocketLike) {
        super("user/deconnexion", io);
    }
}


export interface ParamAskPasswordReset {
    email: string;
}

export interface ReturnAskPasswordReset {
    resetOK: boolean;
}

export class RecepterAskPasswordReset extends Recepter<ReturnAskPasswordReset, ParamAskPasswordReset>{
    constructor(io: SocketLike) {
        super("user/askResetMdp", io);
    }
}

export interface ParamPassReset {
	user_id: number;
	token: string;
	password: string;
}

export interface ReturnPassReset {
	mdpResetOK: boolean;
}

export class RecepterPasswordReset extends Recepter<ReturnPassReset, ParamPassReset>{
    constructor(io: SocketLike) {
        super("user/resetMdp", io);
    }
}


export interface ParamDataGet {
    token: string;
}

export interface ReturnDataGet {
    dataset: any[];
    aggregated: any[];
}

export class RecepterDataGet extends Recepter<ReturnDataGet, ParamDataGet>{
    constructor(io: SocketLike) {
        super('data/get', io)
    }
}