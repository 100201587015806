/**
 * This class contains static methods for global services
 */
export class MainService {

    // Dates
    // public dateFormat = 'yy-mm-dd';
    // public dateFormatFr = 'dd-mm-yy';
    // public dateFormatEn = 'yy-mm-dd';
    public static dateFormatIntl = 'fr-FR';
    public static dateFormatIntlEn = 'en-GB';
    public static dateFormatIntlUS = 'en-US';
    public static dateFormatRegex = "[0-9]{4}[/,-]{1}[0-9]{2}[/,-]{1}[0-9]{2}"; // 2017/05/05 or 2017-05-05

    // public static displayMenuNavLog = true;
    // public static displayMenuFinderLog = true;
    // public static displayMenuElementLog = true;

    /*********************************************************************************************************************/
    /** ************************************************ LANGUAGE ***************************************************** **/
    /*********************************************************************************************************************/
    // Language selected in top menu
    static isFrench(){ return true };
    static isEnglish(){ return false };


    /*********************************************************************************************************************/
    /** *************************************************** DATE ****************************************************** **/
    /** https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/DateTimeFormat **/
    /** https://www.carlrippon.com/formatting-dates-and-numbers-in-react/ **/
    /** https://momentjs.com/docs/ => moment().format("DD/MM/YYYY H:m:s") **/
    /*********************************************************************************************************************/
    static formatDate(myDate: Date, format?: string) {
        format = format || MainService.dateFormatIntl;
        const options = {
            year: "numeric", month: "numeric", day: "numeric",
            hour: "numeric", minute: "numeric", second: "numeric",
            hour12: false
        };

        return Intl.DateTimeFormat(format, options).format(new Date(myDate));
    }
    static formatDateEn(myDate: Date): string { return this.formatDate(myDate, this.dateFormatIntlEn); }
    static formatDateFr(myDate: Date): string { return this.formatDate(myDate, this.dateFormatIntl); }

    // Time zone
    static formatDate_withoutTimeZone(myDate: Date, format?: string): string {
        if(this.isEmptyString(myDate)) return "";
        format = format || MainService.dateFormatIntl;
        let timeZone = (new Date(myDate)).getTimezoneOffset();
        let newDate = new Date((new Date(myDate)).getTime()+(timeZone*60*1000));
        return MainService.formatDate(newDate, format);
    }

    static formatDateEn_withoutTimeZone(myDate: Date): string { return this.formatDate_withoutTimeZone(myDate, MainService.dateFormatIntlEn);}
    static formatDateFr_withoutTimeZone(myDate: Date): string { return this.formatDate_withoutTimeZone(myDate, MainService.dateFormatIntl); }

    // String
    static formatStringToDate(myDate: string, format?: string): Date | null{
        if(this.isEmpty(myDate)) return null;
        // format = format || MainService.dateFormatIntl;
        let timeZone = (new Date(myDate)).getTimezoneOffset();
        return new Date((new Date(myDate)).getTime()+(timeZone*60*1000));
    }

    static formatDateIntoMonthString(dateStr: string, format?: string): string {
        let date = this.formatStringToDate(dateStr, format);
        if(date === null) return '';
        else {
            let month = date.toLocaleString("en-us", {'month': "long"});
            return month.substr(0, 3);
        }
    }

    static addOrRemoveDayToDate(initDate: Date, dayNumber: number){
        initDate = new Date(initDate);
        return new Date(initDate.setDate(initDate.getDate() + dayNumber));
    }

    static addOrRemoveMonthToDate(initDate: Date, monthNumber: number){
        return new Date(initDate.setMonth(initDate.getMonth() + monthNumber));
    }

    static addOrRemoveYearToDate(initDate: Date, yearNumber: number){
        return new Date(initDate.setFullYear(initDate.getFullYear() + yearNumber));
    }

    static isValidFormatDateString(date: string): boolean{
        const isMatched = date.match(this.dateFormatRegex);
        return isMatched !== null ? this.isNotEmptyString(date) && date.length === 10 && isMatched && isMatched.length > 0 : false;
    }

    static isValidFormatDate(date: Date): boolean{
        return this.isValidFormatDateString(date.toString());
    }

    static monthDifference(d1: Date, d2: Date): number {
        let months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months;
    }

    static dayDifference(d1: Date, d2: Date): number {
        return Math.round((new Date(d1).getTime() - new Date(d2).getTime())/(1000*60*60*24));
    }

    static convertFormatDateIntoD3Format(dateStr: string): string{
        if(dateStr === "DD") return "%d";
        else if(dateStr === "MM") return "%m";
        else if(dateStr === "YY") return "%y";
        else if(dateStr === "YYYY") return "%Y";
        else if(dateStr === "DD/MM/YYYY") return "%d/%m/%Y";
        else if(dateStr === "MM/DD/YYYY") return "%m/%d/%Y";
        else if(dateStr === "DD/MM/YY") return "%d/%m/%y";
        else if(dateStr === "MM/DD/YY") return "%m/%d/%y";
        else if(dateStr === "DD/MM/YYYY h:m:s") return "%d/%m/%Y %I:%M:%S";
        else if(dateStr === "MM/DD/YYYY h:m:s") return "%m/%d/%Y %I:%M:%S";
        else if(dateStr === "DD/MM/YYYY H:m:s") return "%d/%m/%Y %H:%M:%S";
        else if(dateStr === "MM/DD/YYYY H:m:s") return "%m/%d/%Y %H:%M:%S";
        
        else if (dateStr === "DD-MM") return '%d-%m';
        else if (dateStr === "DD/MM") return '%d-%m';
        else if (dateStr === "MM-DD") return '%m-%d';
        else if (dateStr === "MM/DD") return '%m/%d';
        else if (dateStr === "MM-DD-YYYY") return '%m-%d-%Y';
        else if (dateStr === "MM/DD/YYYY") return '%m/%d/%Y';
        else if (dateStr === "YYYY-DD-MM") return '%Y-%d-%m';
        else if (dateStr === "YYYY-MM-DD") return '%Y-%m-%d';
        else if (dateStr === "DD-MM h:m:s") return '%d-%m %I:%M:%S';
        else if (dateStr === "DD/MM h:m:s") return '%d-%m %I:%M:%S';
        else if (dateStr === "MM-DD h:m:s") return '%m-%d %I:%M:%S';
        else if (dateStr === "MM/DD h:m:s") return '%m/%d %I:%M:%S';
        else if (dateStr === "MM-DD-YYYY h:m:s") return '%m-%d-%Y %I:%M:%S';
        else if (dateStr === "MM/DD/YYYY h:m:s") return '%m/%d/%Y %I:%M:%S';
        else if (dateStr === "YYYY-DD-MM h:m:s") return '%Y-%d-%m %I:%M:%S';
        else if (dateStr === "YYYY-MM-DD h:m:s") return '%Y-%m-%d %I:%M:%S';
        return dateStr;
    }

    static convertD3FormatIntoFormatDate(dateStr: string): string{
        if(dateStr === "%d") return "DD";
        else if(dateStr === "%m") return "MM";
        else if(dateStr === "%y") return "YY";
        else if(dateStr === "%Y") return "YYYY";
        else if(dateStr === "%d/%m/%Y") return "DD/MM/YYYY";
        else if(dateStr === "%m/%d/%Y") return "MM/DD/YYYY";
        else if(dateStr === "%d/%m/%y") return "DD/MM/YY";
        else if(dateStr === "%m/%d/%y") return "MM/DD/YY";
        else if(dateStr === "%d/%m/%Y %I:%M:%S") return "DD/MM/YYYY h:m:s";
        else if(dateStr === "%m/%d/%Y %I:%M:%S") return "MM/DD/YYYY h:m:s";
        else if(dateStr === "%d/%m/%Y %H:%M:%S") return "DD/MM/YYYY H:m:s";
        else if(dateStr === "%m/%d/%Y %H:%M:%S") return "MM/DD/YYYY H:m:s";
        
        else if (dateStr === "%d-%m") return 'DD-MM';
        else if (dateStr === "%d-%m") return 'DD/MM';
        else if (dateStr === "%m-%d") return 'MM-DD';
        else if (dateStr === "%m/%d") return 'MM/DD';
        else if (dateStr === "%m-%d-%Y") return 'MM-DD-YYYY';
        else if (dateStr === "%Y-%m-%d") return 'YYYY-MM-DD';
        else if (dateStr === "%m/%d/%Y") return 'MM/DD/YYYY';
        else if (dateStr === "%Y-%d-%m") return 'YYYY-DD-MM';
        else if (dateStr === "%d-%m %I:%M:%S") return 'DD-MM h:m:s';
        else if (dateStr === "%d-%m %I:%M:%S") return 'DD/MM h:m:s';
        else if (dateStr === "%m-%d %I:%M:%S") return 'MM-DD h:m:s';
        else if (dateStr === "%m/%d %I:%M:%S") return 'MM/DD h:m:s';
        else if (dateStr === "%m-%d-%Y %I:%M:%S") return 'MM-DD-YYYY h:m:s';
        else if (dateStr === "%m/%d/%Y %I:%M:%S") return 'MM/DD/YYYY h:m:s';
        else if (dateStr === "%Y-%d-%m %I:%M:%S") return 'YYYY-DD-MM h:m:s';
        else if (dateStr === "%Y-%m-%d %I:%M:%S") return 'YYYY-MM-DD h:m:s';
        return dateStr;
    }

    /*********************************************************************************************************************/
    /** ************************************************* LANGUAGE **************************************************** **/
    /*********************************************************************************************************************/


    /*********************************************************************************************************************/
    /** ******************************************* CONVERT / FORMAT ************************************************** **/
    /*********************************************************************************************************************/
    // toFixedCustom(value, decimal){
    //     return parseInt(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
    // }

    static convertToFloat(value: any, decimal: number = 2): number{
        return this.isEmpty(value) ? value : parseFloat(value.toFixed(decimal));
    }

    // convertToFloatWithCustomToFixed(value: any, decimal: number = 2): number{
    //     return this.isEmpty(value) ? value : parseFloat(this.toFixedCustom(value, decimal));
    // }

    static convertToFloatInLocaleString(value: any, decimal: number = 2, locale?: string): string{
        return this.isEmpty(value) ? value : parseFloat(value).toLocaleString(locale, {minimumFractionDigits: decimal, maximumFractionDigits: decimal});
    }

    static convertToFloatInUSLocaleString(value: any, decimal: number = 2): string{
        return this.convertToFloatInLocaleString(value, decimal, "en-US");
    }

    static formatPrice(value: any, decimal: number, currency: string): string{
        if(this.isEmptyString(value) || isNaN(value)) return '';
        switch(currency){
            case "$": return currency + this.convertToFloatInUSLocaleString(value, decimal);
            default: return this.convertToFloatInLocaleString(value, decimal) + currency;
        }
    }
    static formatPriceInUs(value: any, decimal: number){ return this.formatPrice(value, decimal, "$"); }

    static formatPriceWithMinAndMaxDigits(value: any, decimal: number, currency: string): string{
        if(this.isEmptyString(value) || isNaN(value)) return '';
        switch(currency){
            case "$": return currency + this.convertToFloatInUSLocaleString(value);
            default: return this.convertToFloatInLocaleString(value, 2, "fr-FR") + currency;
        }
    }

    static convertPriceToValue(price: string, currency: string): number{
        if(this.isEmptyString(price) || this.isEmptyString(currency)) return parseFloat(price);

        switch(currency){
            case "$": return parseFloat(price.replace(currency, "").replace(",", "").replace(/\s/g,""));
            case "€": return parseFloat(price.replace(currency, "").replace(",", "").replace(/\s/g,""));
            default: return parseFloat(price.replace(currency, "").replace(/\s/g,""));
        }
    }

    /**
     * Also use css style formatComment
     */
    static formatComment(comment?: string, size: number = 10){
        return comment ? (comment.length > size ? comment.substring(0,size) + "..." : comment) : "";
    }

    static getDepartmentFromPostCode(postCode: string): string{ return this.isNotEmptyString(postCode) ? postCode.substr(0,2) : postCode; }

    static formatPhone(phone: string): string{
        if(this.isEmptyString(phone)) return "";
        else return this.isFrench() ? this.formatPhoneFr(phone) : this.formatPhoneUs(phone);
    }

    static formatPhoneFr(phone: string): string{
        return phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
    }

    static formatPhoneUs(phone: string): string{
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }

    /**
     * This function returns a phone number without space, french indicator (+33) and remove the first zero
     */
    static getRawPhone(phone: string): string{
        return phone;
    }

    static formatFirstName(firstName: string){
        return this.isNotEmpty(firstName) ? (firstName.toLowerCase().substr(0,1).toUpperCase() + firstName.toLowerCase().substr(1)) : firstName;
    }


    /*********************************************************************************************************************/
    /** ************************************************* NUMBER ****************************************************** **/
    /*********************************************************************************************************************/
    static roundWithDecimals(value: number, decimals: number) {
        return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
    }

    static getNumberValueFromTextInput(newValue: any, minimum?: any, maximum?: any, format?: string): any{
        if(isNaN(newValue)) return '';
        else if(minimum && parseFloat(newValue) < parseFloat(minimum)) newValue = parseFloat(minimum);
        else if(maximum && parseFloat(newValue) > parseFloat(maximum)) newValue = parseFloat(maximum);
        if(format === '%' && !newValue.toString().endsWith(".")) newValue = newValue/100;
        return newValue;
    }

    static isNumber(value: any): boolean {
        return !isNaN(value);
    }


    /*********************************************************************************************************************/
    /** ************************************************* EMPTY ******************************************************* **/
    /*********************************************************************************************************************/
    public static isUndefined(value: any): boolean{
        return value === undefined || value === "undefined";
    }

    public static isNotUndefined(value: any): boolean{
        return !this.isUndefined(value);
    }

    public static isNotEmpty(value: any): boolean{
        return !this.isUndefined(value) && value != null;
    }

    public static isNotEmptyString(value: any): boolean{
        return this.isNotEmpty(value) && value !== "";
    }

    public static isEmpty(value: any): boolean{
        return !this.isNotEmpty(value);
    }

    public static isEmptyString(value: any): boolean{
        return !this.isNotEmptyString(value);
    }

    public static isAnObject(value: any): boolean{
        return typeof value === "object";
    }

    public static isNotEmptyNumber(value: any): boolean{
        return this.isNotEmpty(value) && !isNaN(value);
    }


    /*********************************************************************************************************************/
    /** ************************************************* STRING ****************************************************** **/
    /*********************************************************************************************************************/
    /**
     * This function compares 2 string by replacing all special characters by _ & in upper case
     */
    static compareStrings(string1: string, string2: string){
        if(this.isEmptyString(string1) && this.isEmptyString(string2)) return true;
        else if(this.isEmptyString(string1) || this.isEmptyString(string2)) return false;
        else {
            let newString1 = this.getRawUpperCase(string1);
            let newString2 = this.getRawUpperCase(string2);
            return (newString1 === newString2) || (newString1.indexOf(newString2) != -1) || (newString2.indexOf(newString1) !== -1);
        }
    }

    /**
     * This function returns the string in upper case wihtout space & with special character replacing by _
     */
    static getRawUpperCase(value: string): string{ return this.isNotEmptyString(value) ? value.replace(/ /g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() : value; }


    /*********************************************************************************************************************/
    /** ************************************************** ARRAY ****************************************************** **/
    /*********************************************************************************************************************/
    static getUnique(array: any[]): any[]{
        return array.filter(function (value, index, self) {
            return self.indexOf(value) === index;
        });
    }

    static areSameArrays(array1: any[], array2: any[]): boolean{
        if(!array1 && !array2) return true;
        return array1 && array2 && Array.isArray(array1) && Array.isArray(array2) && array1.length === array2.length
            && array1.every((value, index) => value === array2[index]) && array2.every((value, index) => value === array1[index]);
    }


    /*********************************************************************************************************************/
    /** ************************************************** OBJECT ***************************************************** **/
    /*********************************************************************************************************************/
    static areSameObjectsOfArrays(obj: any, source: any) {
        if(!obj && !source) return true;
        if(!obj && source || obj && !source) return false;
        if(Object.keys(obj).length === 0 && Object.keys(source).length === 0) return true;

        const sens1 = Object.keys(source).every(key => {
            return obj.hasOwnProperty(key) && MainService.areSameArrays(source[key], obj[key])
        });

        const sens2 = Object.keys(obj).every(key => {
            return source.hasOwnProperty(key) && MainService.areSameArrays(source[key], obj[key]);
        });
        return sens1 && sens2;
    }


    /*********************************************************************************************************************/
    /** ************************************************* COLORS ****************************************************** **/
    /*********************************************************************************************************************/
    /**
     * From site : https://www.sitepoint.com/javascript-generate-lighter-darker-color/
     * colorLuminance("6699CC", 0.2); // "#7ab8f5" - 20% lighter
     * colorLuminance("69C", -0.5);     // "#334d66" - 50% darker
     * @param hex
     * @param lum
     * @returns {string}
     */
    static colorLuminance(hex: string, lum: number): string {

        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6)
            hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
        lum = lum || 0;

        // convert to decimal and change luminosity
        let rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i*2,2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00"+c).substr(c.length);
        }

        return rgb;
    }





    /*********************************************************************************************************************/
    /** ************************************************** LOGS ******************************************************* **/
    /*********************************************************************************************************************/
    public static consoleLog(componentClass: React.PureComponent, logs: any){
        console.log(logs);
        // if(componentClass === )
    }


    /*********************************************************************************************************************/
    /** ************************************************* Labels ****************************************************** **/
    /*********************************************************************************************************************/
    // public static getLabelForRightMenu(label: string){
    //     return label && properties && properties.rightMenuLabels && properties.rightMenuLabels[label] ? properties.rightMenuLabels[label] : label;
    // }


    /*********************************************************************************************************************/
    /** ************************************************** IMAGE ****************************************************** **/
    /*********************************************************************************************************************/
    /**
     * This function transform a svg into a string url usable for a image src. The URL is living only during the document life.
     */
    public static getImageUrlFromSvgTagName(svgTagName: string): string {
        let svgString = this.convertSvgIntoString(svgTagName);
        if(svgString !== '') return this.getImageUrlFromSvgText(svgString);
        else return '';
    }

    /**
     * This function convert a svg into a text (to save in DB)
     */
    public static convertSvgIntoString(svgTagName: string): string {
        let svg = document.getElementsByTagName(svgTagName)[0];
        if(svg) return (new XMLSerializer()).serializeToString(svg);
        else return '';
    }

    /**
     * This function returns the image URI for a svg in string
     */
    public static getImageUrlFromSvgText(svgText: string): string {
        // console.log('BLOB', new Blob([svgText], {type: 'image/svg+xml;charset=utf-8'}))

        /**
         * Note : removing all foreignObjects from the svg string,
         * otherwise createObjectUrl and then canvas.toDataUrl
         * creates a cross origin conflict
         * No other solution for now !
         */
        svgText = svgText.replace(/<foreignObject .*>.*<\/foreignObject>/g, '');
        return URL.createObjectURL(new Blob([svgText], {type: 'image/svg+xml;charset=utf-8'}));
    }

    /**z
     * This function set a image src with a svg url
     * @param cleanBefore : indicates if the div contains only the div image or if we append
     */
    public static async setSvgToImage(divId: string, url: string, cleanBefore: boolean = false): Promise<string>{
        // console.log('CLICK SAVE 2.2.1')

        return new Promise(resolve => {
        // console.log('CLICK SAVE 2.2.1.1')

            // let div = document.getElementById(divId) as HTMLImageElement;
            let div = document.createElement("div");
        // console.log('CLICK SAVE 2.2.1.2', div)

            if (div) {
        // console.log('CLICK SAVE 2.2.1.3')

                let image = new Image();
                let canvas = document.createElement('canvas'),
                    ctx = canvas.getContext("2d");

                // console.log('CANVAS', canvas, ctx, image)

                // image.setAttribute('crossOrigin', 'Anonymous');
                image.onload = () => {
                    // console.log('CLICK SAVE 2.2.3')
                    if (ctx) {
                        canvas.width = image.width;
                        canvas.height = image.height;
                        ctx.drawImage(image, 0, 0, image.width, image.height);
                        URL.revokeObjectURL(url);
                        var imgURI = canvas.toDataURL();//'image/png');
                        // document.removeChild(div);
                        resolve(imgURI.toString());
                    }
                }

                image.src = url;
                // console.log('CLICK SAVE 2.2.2')

                // image.setAttribute('width', width.toString());
                // image.setAttribute('height', height.toString());
                // image.addEventListener('load', () => {
                //     URL.revokeObjectURL(url);
                // }, {once: true});
                if(cleanBefore) div.innerHTML = '';
            }
            else
             resolve('')
        })
    }


    static httpGet(theUrl: string)
    {
        var xmlHttp = new XMLHttpRequest();

        xmlHttp.onreadystatechange = () => {
            if (xmlHttp.readyState === 4){// complete
                if (xmlHttp.status === 200) { //success

                }
                else {
                    //Error !
                    console.log('ERROR')
                }
            } 
        }

        xmlHttp.open( "GET", theUrl, false ); // false for synchronous request
        xmlHttp.send( null );
        return xmlHttp.responseText;
    }

    static validateEmailAddress(email: string) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
}
