import { Files } from "../../redux/reducers/ReducerWaiting";
import { SocketLike } from "../ServerInterfaces";
import { Recepter } from "./Recepter";

export interface FileUploadReturn {
    error?: boolean;
    linesNumber: number;
}

export interface FileUploadSend {
    token: string;
    file: File;
    fileName: Files;
}

export class RecepterFileUpload extends Recepter<FileUploadReturn, FileUploadSend> {

    constructor(io: SocketLike) {
        super("file/upload", io);
    }
}