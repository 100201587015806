import ServerCore from "./ServerCore";
import { PaquetReceive, Recepter } from "./recepters/Recepter";


export abstract class ServerManager {
    get recepters(): ReadonlyArray<Recepter<any, any>> {
        return this._recepters;
    }

    static readonly ERRORS = {
        IO_NOT_CONNECTED: "io not connected"
    };

    protected readonly core: ServerCore;
    private _recepters!: ReadonlyArray<Recepter<any, any>>;

    constructor(core: ServerCore) {
        this.core = core;
    }

    init(): Promise<void> {
        return new Promise(resolve => {

            if (!this.core.isConnected()) {
                throw new Error(ServerManager.ERRORS.IO_NOT_CONNECTED);
            }

            const listRecepters: Recepter<any, any>[] = [];

            const receptersClass = this.getAllRecepters();

            for (const c of receptersClass) {
                const recepter = new (c as Recepter<any, any> & any)(this.core);
                listRecepters.push(recepter);
                // try {
                    this.core.on(recepter.path, (p: PaquetReceive<any>) => {
                        (recepter.on as (data: any) => any)(p);
                    });
                // }
                // catch(e) {
                    // console.log('Erreur recepter : ', e);
                // }
            }

            // console.log(this.core, listRecepters)

            this._recepters = listRecepters;

            resolve();
        });
    }

    get<C extends Recepter<any, any>>(recepterClass: { new(...args: any[]): C }): C {
        // console.log('recepter', recepterClass)
        const recepter = this._recepters.find(r => r.constructor.name === recepterClass.name) as C;

        if (!recepter) {
            throw new Error("Recepter not found : " + recepterClass.name);
        }

        return recepter;
    }

    abstract getAllRecepters(): { new(...args: any[]): Recepter<any, any> }[];

}
