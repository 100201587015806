import { ServerManager } from './ServerManager'
import { Recepter } from './recepters/Recepter'
import ControllerMain from '../controllers/ControllerMain'

import {
    RecepterUserLogin, RecepterAskPasswordReset, RecepterPasswordReset, RecepterUserLogout, RecepterDataGet,
} from './recepters/ReceptersUser'
import { RecepterFileUpload } from './recepters/RecepterFiles'



export default class ServerManagerUI extends ServerManager {

    setController(ctler: ControllerMain): void {
        this.recepters.forEach(r => {
            r.setController(ctler)
        })
    }

    getAllRecepters(): (new (...args: any[]) => Recepter<any, any>)[] {
        return [
            RecepterUserLogin,
            RecepterAskPasswordReset,
            RecepterPasswordReset,
            RecepterUserLogout,
            RecepterDataGet,
            RecepterFileUpload
        ]
    }

}
