import { MyReducer } from "../MyReducer";
import { StoreAction } from "../StoreManager";
import { Action } from "redux";
import { ParamPassReset } from "../../server/recepters/ReceptersUser";
import { RPassProps } from "../../views/resetPassword/ViewResetPassword";

export enum ResetPasswordBackActionsNames  {
	RESET_PASSWORD="RESET_PASSWORD",
	RESET_PASSWORD_END = "RESET_PASSWORD_END"
}


export interface ActionResetPass extends Action<"RESET_PASSWORD">, ParamPassReset {
}

export interface ActionResetPassBack extends Action<"RESET_PASSWORD_END"> {
	resetOk: boolean;
}


export type ResetPasswordBackActions = ActionResetPass | ActionResetPassBack;


export default class ReducerResetPasswordBack extends MyReducer<RPassProps, StoreAction> {
	getInitialState(): RPassProps {
		return {
			name: "RESET_PASSWORD",
			resetOk: undefined
		}
	}

    protected onReduce(state: RPassProps | undefined, action: StoreAction): RPassProps {
		if (!state)
			state = this.getInitialState();

		switch(action.type) {
			
			case ResetPasswordBackActionsNames.RESET_PASSWORD:
				this.controllerMain.controllerUser.resetPassword(action);
				break;
			case ResetPasswordBackActionsNames.RESET_PASSWORD_END:
				state.resetOk = action.resetOk;
				break;
		}
		
		
		
		
		return {
			...state
		}
	}

}