import { MyReducer } from "../MyReducer";
import { RPassAskProps } from "../../views/resetPasswordAsk/ViewResetPasswordAsk";
import { StoreAction } from "../StoreManager";
import { Action } from "redux";
import { ParamPassReset } from "../../server/recepters/ReceptersUser";
import { MessageType } from "antd/lib/message";
import { ResetPasswordBackActionsNames } from "./ReducerResetPasswordBack";

export enum ResetPasswordActionsNames {
	ASK_PASSWORD_RESET= "ASK_PASSWORD_RESET",
	RESET_PASSWORD="RESET_PASSWORD",
	ASK_PASSWORD_RESET_END= "ASK_PASSWORD_RESET_END"
}

export interface ActionAskResetPass extends Action<"ASK_PASSWORD_RESET"> {
	email: string;
	message: () => MessageType;
}

export interface ActionResetPass extends Action<"RESET_PASSWORD">, ParamPassReset {
}

export interface ActionAskResetPassEnd extends Action<"ASK_PASSWORD_RESET_END"> {
	resetOk: boolean;
}

export type ResetPasswordActions = ActionAskResetPass | ActionResetPass | ActionAskResetPassEnd;


export default class ReducerResetPassword extends MyReducer<RPassAskProps, StoreAction> {
	getInitialState(): RPassAskProps {
		return {
			name: "RESET_PASSWORD_ASK",
		}
	}

    protected onReduce(state: RPassAskProps | undefined, action: StoreAction): RPassAskProps {
		if (!state)
			state = this.getInitialState();

		switch(action.type) {
			case ResetPasswordActionsNames.ASK_PASSWORD_RESET: 
				this.controllerMain.controllerUser.askResetPassword(action.email, action.message);
			break;
			case ResetPasswordActionsNames.RESET_PASSWORD:
				this.controllerMain.controllerUser.resetPassword(action);
				break;
			case ResetPasswordActionsNames.ASK_PASSWORD_RESET_END:
				// console.log('RETOUR ', action.resetOk)
				// if (action.resetOk) state.resetSent = true;
				break;
			
		}
		
		
		
		
		return {
			...state
		}
	}

}