import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { ActionCheckLogin, LoginActions } from "../../../redux/reducers/ReducerLogin";
import { MainErrorProps, MainErrorState } from "../../../services/ErrorService";
import { PageViewProps } from "../../Page";
import { PageRoutage } from "../../router/PageRoutage";

export interface ParamsMagasin {
    identifiant?: string;
    mdp?: string;
}

export const LoginViaURLRoute = new class extends PageRoutage<any> {
    getPath(p?: ParamsMagasin) {
        let path = '/connect/:identifiant/:mdp';
        
        if (p) {
            path = path.replace(':identifiant', p.identifiant + '');
            path = path.replace(':mdp', p.mdp + '');
        }

        return path;
    }
}

export interface LoginViaURLProps extends MainErrorProps, PageViewProps<'LOGIN_VIA_URL'> {
    redirect?: string;
}


export interface LoginViaURLState extends MainErrorState {

}

interface LoginViaURLDispatchProps {
    connect: (user: string, mdp: string) => void;
}

type FullLoginViaURLProps = LoginViaURLProps & LoginViaURLDispatchProps & RouteComponentProps<ParamsMagasin>;


class LoginViaURLView extends React.PureComponent<FullLoginViaURLProps, LoginViaURLState> {

	constructor(p: FullLoginViaURLProps) {
		super(p);
		
		if (p.match.params.identifiant && p.match.params.mdp) {
			this.props.connect(p.match.params.identifiant, p.match.params.mdp);
		}
    }
    
    render() {
        if (this.props.redirect) {
            return <Redirect to={this.props.redirect} />
        }

        return <div id="loginUrl"></div>

    }

}

const mapDispatchToProps = (dispatch: Dispatch, props: LoginViaURLProps): LoginViaURLDispatchProps => {
    return {
        connect: (user: string, mdp: string) => {
			dispatch({type: LoginActions.LOGIN_CHECK_USER_AND_PASSWORD, email: user, password: mdp} as ActionCheckLogin)
		}
    }
}

const mapStateToProps = (state: any): LoginViaURLProps => {
    return {
        ...state.pageProps as LoginViaURLProps
    };
}

export default connect<{}, LoginViaURLDispatchProps, LoginViaURLProps>(mapStateToProps, mapDispatchToProps)(LoginViaURLView);