import {RouteComponentProps} from "react-router";
import * as React from "react";
import {Link} from "react-router-dom";

import './error.css';


interface Params {
    subpath: string;
}

export interface ErrorViewProps {

}

export interface ErrorViewState {

}

type ErrorViewPropsRoute = ErrorViewProps & RouteComponentProps<Params>;

export default class ErrorView extends React.PureComponent<ErrorViewPropsRoute, ErrorViewState> {

    render(): React.ReactNode {

        const {location: {pathname}} = this.props;

        // console.log('P', this.props);

        return <div className={'page page-error'}>
            <div className={'error-content'}>
                <h2>Vizu All</h2>
                <div>
                    <b>404.</b> That’s an error.
                </div>
                <br/>
                <div>
                    The requested URL <b>{pathname}</b> was not found on this server.<br/>
                    That’s all we know.
                </div>
                <br/>
                <Link to={'/'} className={'link'}>Go back to home</Link>
            </div>
        </div>;
    }

}