
export abstract class PageRoutage<P> {

    abstract getPath(params?: P): string;

    checkPath(path: string) {
        const pathPattern = this.getPath();

        const patternSplit = pathPattern.split('/');
        const pathSplit = path.split('/');

        let patternVal: string;
        let pathVal: string;
        for (let i = 0; i < patternSplit.length; i++) {
            patternVal = patternSplit[i];
            pathVal = pathSplit[i];

            if (pathVal === patternVal) {
                continue;
            }

            if (!pathVal.length) {
                return false;
            }

            if (patternVal[0] === ':') {
                continue;
            }

            return false;
        }

        return true;
    }
}