import ControllerMain from './ControllerMain';
import { RecepterUserLogin, RecepterUserLogout, UserLoginReceive, RecepterAskPasswordReset, ParamPassReset, RecepterPasswordReset, RecepterDataGet } from '../server/recepters/ReceptersUser';
import { ResetPasswordActionsNames } from '../redux/reducers/ReducerResetPassword';
import { MessageType } from 'antd/lib/message';
import { ResetPasswordBackActionsNames } from '../redux/reducers/ReducerResetPasswordBack';
import { WaitingActionsNames } from '../redux/reducers/ReducerWaiting';


export interface UserMetadata {
    token: string;
    email: string;
}

export default class ControllerUser {
    private readonly controllerMain: ControllerMain;

    constructor(controllerMain: ControllerMain) {
        this.controllerMain = controllerMain;
    }


    async login(email: string, password: string){
        if (!this.controllerMain.serverManagerUI || !this.controllerMain.serverManagerUI.recepters) {
            await this.controllerMain.serverPromise;
        }

        if(this.controllerMain.serverManagerUI){
            return await this.controllerMain.serverManagerUI.get<RecepterUserLogin>(RecepterUserLogin)
                .send({
                    email,
                    password: password
                }).then((result : UserLoginReceive)=> {return result});
        }

        return null;
    }

    async logout(token: string){
        if (!this.controllerMain.serverManagerUI || !this.controllerMain.serverManagerUI.recepters) {
            await this.controllerMain.serverPromise;
        }

        if(this.controllerMain.serverManagerUI){
            await this.controllerMain.serverManagerUI.get<RecepterUserLogout>(RecepterUserLogout)
                .send({
                    token
                }).catch((error: any) => this.controllerMain.catchError(error));
        }
    }

    async askResetPassword(email: string, message: () => MessageType) {
        if (!this.controllerMain.serverManagerUI || !this.controllerMain.serverManagerUI.recepters) {
            await this.controllerMain.serverPromise;
        }

        if(this.controllerMain.serverManagerUI){
            await this.controllerMain
                        .serverManagerUI
                        .get<RecepterAskPasswordReset>(RecepterAskPasswordReset)
                        .send({
                            email
                        })
                        .then((e) => {
                            if (e) {
                                message()
                            }
                        });
        }
    }

    async resetPassword({password, token, user_id}: ParamPassReset) {
        if (!this.controllerMain.serverManagerUI || !this.controllerMain.serverManagerUI.recepters) {
            await this.controllerMain.serverPromise;
        }

        if(this.controllerMain.serverManagerUI){
                await this.controllerMain
                            .serverManagerUI
                            .get<RecepterPasswordReset>(RecepterPasswordReset)
                            .send({
                                password,
                                token,
                                user_id
                            })
                            .then((e) => {
                                if (e)
                                {
                                    this.controllerMain.dispatch({type: ResetPasswordBackActionsNames.RESET_PASSWORD_END, resetOk: true})
                                }
                                else {
                                    this.controllerMain.dispatch({type: ResetPasswordBackActionsNames.RESET_PASSWORD_END, resetOk: false})
                                }
                            })
                            .catch((e) => {
                                this.controllerMain.dispatch({type: ResetPasswordBackActionsNames.RESET_PASSWORD_END, resetOk: false})
                            })
        }
    }


    //DATA
    async askData() {
        if (!this.controllerMain.serverManagerUI || !this.controllerMain.serverManagerUI.recepters) {
            await this.controllerMain.serverPromise;
        }

        if(this.controllerMain.serverManagerUI){
            let token = this.controllerMain.userMetadata?.token

            if (token) {
                await this.controllerMain
                            .serverManagerUI
                            .get<RecepterDataGet>(RecepterDataGet)
                            .send({
                                token
                            })
                            .then((data) => {
                                this.controllerMain.dispatch({type: WaitingActionsNames.DATA_HERE, ...data})
                            })
            }else {
                this.controllerMain.dispatch({type: WaitingActionsNames.ERROR_RECONNECT})
            }
            

            
        }
        
    }

}