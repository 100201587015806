import * as React from "react";
import { PageViewProps } from "../Page";
import { PageRoutage } from "../router/PageRoutage";
import loader from "../../_assets/loader.gif";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ActionNoMoreError, ActionUploadFile, Files, WaitingActionsNames } from "../../redux/reducers/ReducerWaiting";
import { InboxOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { Alert, Divider, Drawer, message, notification, Progress, Row, Space, Upload } from "antd";
import { UploadRequestOption } from "rc-upload/lib/interface";

import "./waiting.css"
import * as ReactDOM from "react-dom";
import Dragger from "antd/lib/upload/Dragger";
import { RcFile } from "antd/lib/upload";

export const WaitingViewRoute = new class extends PageRoutage<undefined> {

    getPath() {
        let path = `/app`;

        return path;
    }
    
};

export interface WaitingProps extends PageViewProps<'WAITING'> {
	dataReady?: boolean;
	dataToSend?: {
		dataset: any[];
		aggregated: any[];
	}
	userRole?: string;
	reconnect?: boolean;
	isAdmin?: boolean;
	//When receiving files 1 by 1
	receivingFiles?: {
		nbOfFiles: number;
		files: {
			file_name: string;
			file_data: any[];
		}[]
	}
	fileReceivedOnServer?: {
		file: string;
		nbLines: number
	}[]
	errorOnHeader?: boolean;
	errorOnData?: boolean;
}

export interface WaitingState {
	adminPanelOpen: boolean;
}

interface WaitingDispatchProps {
	askData: () => void;
	updateFile1: (file: File, onSuccess: (lines?: number) => {}, onError: () => {}) => void;
	updateFile2: (file: File, onSuccess: (lines?: number) => {}, onError: () => {}) => void;
	updateFile3: (file: File, onSuccess: (lines?: number) => {}, onError: () => {}) => void;
	updateFileUsers: (file: File, onSuccess: (lines?: number) => {}, onError: () => {}) => void;
	updateFileProperties: (file: File, onSuccess: (lines?: number) => {}, onError: () => {}) => void;
	noMoreError: (header: boolean, data: boolean) => void;
}
type FullWaitingProps = WaitingProps & WaitingDispatchProps;

export class ViewWaiting extends React.Component<FullWaitingProps, WaitingState> {

	private interval: any = "myInter";
	private refFileData: any;
	private refFileUsers: any;

    constructor(props: FullWaitingProps) {
        super(props);
        
		this.props.askData();

		this.state = {
			adminPanelOpen: false
		}
	}

	render() {

		let {adminPanelOpen} = this.state;

		let toDisplay;
		if (this.props.dataReady) {
			toDisplay = <iframe src={process.env.REACT_APP_VIZ_ADDRESS} />
		}
		else 
			toDisplay = <img src={loader} width={229} height={102}/>
		
		let adminPanel;
		if (this.props.isAdmin) {
			adminPanel = <div id="adminPanelButton" onClick={() => this.setState({adminPanelOpen: !this.state.adminPanelOpen})}>
				<CaretLeftOutlined className='handle' />
				<div>
					Fichiers de données
				</div>
			</div>
		}


		return <div id='loaderDiv'>
			{toDisplay}
			{adminPanel}
			<Drawer 
				title="Déversement des fichiers de l'application"
				placement="right"
				closable={true}
				open={adminPanelOpen}
				key='drawer'
				onClose={() => this.setState({adminPanelOpen: false})}
				width="70%"
				>
					<Space size="large" direction="vertical" style={{width: "70%", marginLeft: "15%"}}>
						<div id="file1" className='filesUpload'>
						<Divider style={{fontSize: "20px", fontWeight: "bold"}}>Fichier de données</Divider>
							<p>
								Ce fichier est le fichier data principal de l'application. 
								<br/>
								<b>Merci de s'assurer que ce fichier est au bon format, avec la première ligne contenant le header du fichier.</b>
								
								<br/>
								Colonnes attendues dans ce fichier:  <br/>'Vague'<br/>'RECOMMANDATION'<br/>'NOTE_VENDEUR'<br/>'NOTE_PROJET'<br/>'NOTE_LIVRAISON'<br/>'NOM_Magasin'<br/>'NOTE_POSE'<br/>'RECEPTION_CHANTIER'<br/>'NOTE_SAV'<br/>'NOTE_RECO_NPS'<br/> 'DT_MISE_JOUR'<br/> 'Indexed'

							</p>
							
							<Dragger 
								name='file1'
								multiple={false}
								accept=".csv"
								// ref={this.refFileData}
								customRequest={(option: any) => {
									this.refFileData = option.onProgress
									this.customRequest(option, 1)
								}}
								
							>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">Fichier de données</p>
								<p className="ant-upload-hint">
									Cliquez ou déposez un fichier ici
								</p>
								<p className="ant-upload-hint">
									Le fichier doit être au format CSV.
								</p>
							</Dragger>
						</div>
						<Divider />
						<div id="fileUsers" className='filesUpload'>
							<Divider style={{fontSize: "20px", fontWeight: "bold"}}>Fichier utilisateurs</Divider>
							<p className="ant-text">
								Ce fichier met à jour les utilisateurs de l'application.
								<br/>
								<b>Attention, tous les utilisateurs seront effacés de la base de données et seuls les utilisateurs présents dans ce fichier auront accès à l'application</b>
								<br/>
								Colonnes attendues dans ce fichier: <br/>'Identifiant'<br/>'Code RM / DR'<br/>'Mot de passe'<br/>'Marque'<br/>'Type'<br/>'Infos dans le fichier'
							</p>
							<Dragger 
								name='fileUsers'
								accept=".csv"
								multiple={false}
								// ref={this.refFileUsers}
								customRequest={(option: any) => {
									this.refFileUsers = option.onProgress
									
									this.customRequest(option, 4)
								}}
								
							>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">Fichier utilisateur</p>
								<p className="ant-upload-hint">
									Cliquez ou déposez un fichier ici
								</p>
								<p className="ant-upload-hint">
									Le fichier doit être au format CSV.
								</p>
							</Dragger>
						</div>
					</Space>
				
				
			</Drawer>
		</div>
	}

	private customRequest(options: UploadRequestOption, fileNb: number) {
		let onSuccess = (lines?: number) => options.onSuccess ? options.onSuccess(lines ? lines + " lines" : "", {} as any) : '' as any,
			onError = () => options.onError ? (options.onError('' as any)) : '' as any;
		if ((options.file as RcFile).name && (options.file as RcFile).name.includes('csv'))
		{
			if (fileNb === 1)
				this.props.updateFile1((options.file as RcFile), onSuccess, onError);
			else if (fileNb === 2)
				this.props.updateFile2((options.file as RcFile), onSuccess, onError);
			else if (fileNb === 3)
				this.props.updateFile3((options.file as RcFile), onSuccess, onError);
			else if (fileNb === 4)
				this.props.updateFileUsers((options.file as RcFile), onSuccess, onError);
		}
		else if (fileNb === 5 && (options.file as RcFile).name.includes('json'))
			this.props.updateFileProperties((options.file as RcFile), onSuccess, onError);
		else {
			options.onError ? options.onError(null as any, "The file must be a " + (fileNb === 5 ? '.json':'.csv')) : '' as any;
			console.error("File must be a .csv");
		}

		this.mockLoader(options);
	}

	private mockLoader(options: any) {
		// let cpt = 0;
		// let int = setInterval(() => {
		// 	console.log('OPTIONS', options)
			
		// 	if (options.onProgress && options.status !== 'done')
		// 		options.onProgress({ percent: cpt } as any);
		// 	cpt += Math.random() * 10;
		// 	if (cpt > 100)
		// 		clearInterval(int);
		// }, 300);
		// options.onChange()
	}

	shouldComponentUpdate(nextProps: WaitingProps, nextState: WaitingState) {
		if (nextProps.reconnect) {
			message.error('Une erreur est survenue, merci de bien vouloir vous reconnecter.')
		}

		return true;
	}

	componentDidUpdate() {
		console.log('PROPS', this.props)
		if (this.props.dataReady && this.props.dataToSend) {
			const node = ReactDOM.findDOMNode(this) as Element;
			const contentWindow = (node.firstChild as HTMLIFrameElement).contentWindow;
			// console.log('PROPS', this.props.dataToSend)
			if (contentWindow !== null && this.interval === "myInter") {
					console.log('SENDING TO VIZ', this.props.dataToSend)
					this.interval = setInterval(() => {contentWindow.postMessage({...this.props.dataToSend, agg_dataset: this.props.dataToSend?.aggregated}, '*')}, 2000)
					let stopInterval = () => {clearInterval(this.interval); this.interval = null;}
					window.onmessage = (d: any) => {
						if (['react-devtools-bridge', 'react-devtools-content-script', 
							'react-devtools-detector', "@devtools-page", "react-devtools-inject-backend"]
							.indexOf(d.data.source) !== -1) {
							return; 
						}
						if (d.data.loaded) {
							stopInterval();
						}
							
					}
			}
		}
		
		if (this.props.fileReceivedOnServer) {
			let ind = this.props.fileReceivedOnServer.findIndex(f => f.file === Files.FILE1);
			let ind2 = this.props.fileReceivedOnServer.findIndex(f => f.file === Files.FILE_USERS);
			if (ind > -1) {
				if (this.props.fileReceivedOnServer[ind].nbLines === -1) {
					notification.error({
						message: "Les colonnes du fichier de données ne sont pas conformes. Des colonnes obligatoires manquent dans le fichier déposé. Le fichier est ignoré.",
						onClose: () => this.props.noMoreError(true, true),
						duration: 10000
					})
					this.refFileData({status: "error"})
				}
				else {
					this.refFileData({ percent: 100, status: 'done' } as any);
				}
			}
			// if (ind > -1 && !this.props.errorOnData && !this.props.errorOnData)
			// 	this.refFileData({ percent: 100, status: 'done' } as any);
			
			if (ind2 > -1) {
				if (this.props.fileReceivedOnServer[ind2].nbLines === -1) {
					notification.error({
						message: "Les colonnes du fichier utilisateur ne sont pas conformes. Des colonnes obligatoires manquent dans le fichier déposé. Le fichier est ignoré.",
						onClose: () => this.props.noMoreError(true, true)
					})
					this.refFileUsers({status: "error"})
				}
				else if (this.props.fileReceivedOnServer[ind2].nbLines === -2) {
					notification.error({
						message: "Une erreur dans les données du fichier utilisateur a été détectée. Un utilisateur n'étant pas de type Cuisinella ou Schmidt a un code RM / DR mais ne précise pas quelle colonne regarder dans la colonne Info dans le fichier",
						onClose: () => this.props.noMoreError(true, true),
						duration: 10000
					})
					this.refFileUsers({status: "error"})
				}
				else {
					this.refFileUsers({ percent: 100, status: 'done' } as any);
				}
			}
		}
		
	}
}

const mapDispatchToProps = (dispatch: Dispatch, props: WaitingProps): WaitingDispatchProps => {
    return {
		askData: () => dispatch({type: WaitingActionsNames.ASK_DATA}),
		updateFile1: (file: File, onSuccess: (lines?: number) => {}, onError: () => {}) => 
						dispatch({type: WaitingActionsNames.UPLOAD_FILE, file, fileToUpload: Files.FILE1, onSuccess, onError} as ActionUploadFile),
		updateFile2: (file: File, onSuccess: (lines?: number) => {}, onError: () => {}) => 
						dispatch({type: WaitingActionsNames.UPLOAD_FILE, file, fileToUpload: Files.FILE2, onSuccess, onError} as ActionUploadFile),
		updateFile3: (file: File, onSuccess: (lines?: number) => {}, onError: () => {}) => 
						dispatch({type: WaitingActionsNames.UPLOAD_FILE, file, fileToUpload: Files.FILE3, onSuccess, onError} as ActionUploadFile),
		updateFileUsers: (file: File, onSuccess: (lines?: number) => {}, onError: () => {}) => 
						dispatch({type: WaitingActionsNames.UPLOAD_FILE, file, fileToUpload: Files.FILE_USERS, onSuccess, onError} as ActionUploadFile),
		updateFileProperties: (file: File, onSuccess: (lines?: number) => {}, onError: () => {}) => 	
						dispatch({type: WaitingActionsNames.UPLOAD_FILE, file, fileToUpload: Files.FILE_PROPERTIES, onSuccess, onError} as ActionUploadFile),
		noMoreError: (header: boolean, data: boolean) => dispatch({type: WaitingActionsNames.NO_MORE_ERROR, dataError: data, headerError: header} as ActionNoMoreError)
		
		
    }
}

const mapStateToProps = (state: any): WaitingProps => {
    return {
        ...state.pageProps as WaitingProps
    };
}

export default connect<{}, WaitingDispatchProps, WaitingProps>(mapStateToProps, mapDispatchToProps)(ViewWaiting);
