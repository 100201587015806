import React from "react";
import { PageRoutage } from "../router/PageRoutage";
import { PageViewProps } from "../Page";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import logo from "../../_assets/schmidtLogo.svg";
import { Input, Button, message } from "antd";

import './viewResetPassword.css';
import { Link, RouteComponentProps } from "react-router-dom";
import { LoginRoute } from "../login/LoginView";
import { ActionResetPass, ResetPasswordActionsNames } from "../../redux/reducers/ReducerResetPassword";
import { SaveOutlined, CheckOutlined } from '@ant-design/icons';


export interface Params {
	id_user?: string;
	token?: string;
}


export const ResetPasswordRoute = new class extends PageRoutage< Params > {

    getPath(p?: Params) {
        let path = `/passwordResetSet/:id_user/:token`;

        if(p) {
            path = path
                .replace(':id_user', p.id_user + '')
                .replace(':token', p.token + '');
        }

        return path;
    }
    
};

// type RouteHeaderParams = "code";



export interface RPassProps extends PageViewProps<'RESET_PASSWORD'> {
    resetOk?: boolean;
}

export type ResetPassProps = RPassProps & RouteComponentProps<Params>;

export interface RPassState {
	
	mdp: string;
	confirmMdp: string;
	loading: boolean;

}

interface RPassDispatchProps {
	sendResetPassword: (mdp: string) => void;
}

type FullRPassProps = ResetPassProps & RPassDispatchProps;


export class ViewResetPassword extends React.Component<FullRPassProps, RPassState> {

    constructor(props: FullRPassProps) {
        super(props);
        
        this.state = {
			mdp: '',
			confirmMdp: "",
			loading: false
        }
	}

	render() {
		
		return <div className="viewResetPassword">
           <div id="backViewResetPass">
                <div id="head">
                    <Link to={LoginRoute.getPath()}><img height="50px" src={logo} /> </Link>
                </div>
                <div id="body">
                    <h2 id="title">Réinitialisation de votre mot de passe</h2>
                    <div id="rest">
						<div id="instructions">
							Merci de saisir votre nouveau mot de passe
						</div>
						<div id="inputs">
							<Input type="password" title="Mot de passe" placeholder="Nouveau mot de passe" className="inputPass" value={this.state.mdp} onChange={(e) => this.setState({mdp: e.target.value})}/>
							<Input type="password" title="Mot de passe" placeholder="Confirmation du nouveau mot de passe" className="inputPass" value={this.state.confirmMdp} onChange={(e) => this.setState({confirmMdp: e.target.value})}/>
							<Button type="primary" 
                                shape="round"
                                disabled={!this.checkIfCanSend()}
                                className="btn" 
								onClick={() => {if (this.checkIfCanSend()){ this.props.sendResetPassword(this.state.mdp); this.setState({loading: true})}}}
								loading={this.state.loading}
                                icon={this.props.resetOk ? <CheckOutlined />:<SaveOutlined />}>
                                    Enregistrer
                        	</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
	
	checkIfCanSend(): boolean {
		return this.state.mdp !== "" && this.state.confirmMdp !== "" && 
				this.state.mdp === this.state.confirmMdp
	}

	shouldComponentUpdate(nextProps: ResetPassProps, nextState: RPassState) {
		if (nextProps.resetOk !== undefined) {
			if (nextProps.resetOk) {
				message.success('Votre nouveau mot de passe est enregistré. Merci de retourner à l\'acceuil pour vous connecter.')
				
			}
			else {
				message.error('Une erreur est survenue lors de l\'enregistrement de votre nouveau mot de passe. Merci de réessayer.')
			}
			nextState.loading = false;
		}
		return true;
	}
    
    
} 

const mapDispatchToProps = (dispatch: Dispatch, props: ResetPassProps): RPassDispatchProps => {
    return {
        sendResetPassword: (mdp: string) => {
			if (props.match.params.id_user && props.match.params.token) {
				dispatch({
					type: ResetPasswordActionsNames.RESET_PASSWORD,
					password: mdp,
					token: props.match.params.token,
					user_id: +props.match.params.id_user
				} as ActionResetPass)
			}
		}
    }
}

const mapStateToProps = (state: any): ResetPassProps => {
    return {
        ...state.pageProps as ResetPassProps
    };
}

export default connect<{}, RPassDispatchProps, ResetPassProps>(mapStateToProps, mapDispatchToProps)(ViewResetPassword);