import * as React from "react";

/**
 * This class contains static methods for error services
 */
export interface MainErrorProps {
    isErrorMessage: boolean;
    isSuccessMessage: boolean;
    errorOrSuccessMessage: string;
}

export interface MainErrorState {
    isErrorMessage: boolean;
    isSuccessMessage: boolean;
    errorOrSuccessMessage: string;
}


export class ErrorService {


    constructor() {}

    static getInitErrorState(): MainErrorState{
        return {
            isErrorMessage: false,
            isSuccessMessage: false,
            errorOrSuccessMessage: ''
        }
    }

    static getErrorState(props: MainErrorProps): MainErrorState{
        return {
            isErrorMessage: props.isErrorMessage,
            isSuccessMessage: props.isSuccessMessage,
            errorOrSuccessMessage: props.errorOrSuccessMessage
        }
    }

    static resetErrorState(state: MainErrorState): void{
        state.isErrorMessage = false;
        state.isSuccessMessage = false;
        state.errorOrSuccessMessage = '';
    }

    static isAnyChangeForComponentDidUpdate(props: MainErrorProps, prevProps: MainErrorProps, state:MainErrorState, prevState: MainErrorState) {
        return (props.isErrorMessage != prevProps.isErrorMessage || props.isSuccessMessage != prevProps.isSuccessMessage || props.errorOrSuccessMessage != prevProps.errorOrSuccessMessage
            || (props.isErrorMessage && !state.isErrorMessage && !prevState.isErrorMessage) || (props.isSuccessMessage && !state.isSuccessMessage && !prevState.isSuccessMessage));
    }


    static getErrorDiv(state: MainErrorState){
        let result = '';

        if(state.isErrorMessage || state.isSuccessMessage){
            const iconDiv = React.createElement('div', {'key': 'errorOrSuccessIcon', 'className': "fas fa-exclamation-triangle"});
            const className = state.isErrorMessage ? 'loginError' : 'loginSuccess';
            return React.createElement('div', {'key': 'errorOrSuccessDiv', 'className': className}, [iconDiv, state.errorOrSuccessMessage]);
        }

        return result;
    }
}
