import { PageRoutage } from './router/PageRoutage';
import { MyReducer } from '../redux/MyReducer';
import { StoreAction } from '../redux/StoreManager';
import LoginView, { LoginRoute, LoginProps } from './login/LoginView';
import ReducerLogin from '../redux/reducers/ReducerLogin';
import   ViewResetPasswordAsk, { ResetPasswordAskRoute, RPassAskProps } from './resetPasswordAsk/ViewResetPasswordAsk';
import ReducerResetPassword from '../redux/reducers/ReducerResetPassword';
import ViewResetPassword, { ResetPasswordRoute, RPassProps } from './resetPassword/ViewResetPassword';
import ReducerResetPasswordBack from '../redux/reducers/ReducerResetPasswordBack';
import { ViewWaiting, WaitingProps, WaitingViewRoute } from './waiting/WaitingView';
import ReducerWaiting from '../redux/reducers/ReducerWaiting';
import LoginViaURLView, { LoginViaURLProps, LoginViaURLRoute } from './login/loginURLs/LoginViaUrl';
export interface PageViewProps<N extends string> {
    name: N;
}

export interface Page<P extends PageViewProps<any>> {

    name: P['name'],

    routage: PageRoutage<any>,

    componentClass: React.ComponentClass<P>,

    reducerClass: new(...args: any[]) => MyReducer<P, StoreAction>;

}

export type PageProps = LoginProps;

export const ListPages: Page<any>[] = [

    {
        name: 'LOGIN',
        componentClass: LoginView,
        routage: LoginRoute,
        reducerClass: ReducerLogin,
    } as unknown as Page<LoginProps>,

    {
        name: "LOGIN_VIA_URL",
        componentClass: LoginViaURLView,
        routage: LoginViaURLRoute,
        reducerClass: ReducerLogin
    } as unknown as Page<LoginViaURLProps>,

    {
        name: "RESET_PASSWORD_ASK",
        componentClass: ViewResetPasswordAsk,
        reducerClass: ReducerResetPassword,
        routage: ResetPasswordAskRoute
    } as unknown as Page<RPassAskProps>,

    {
        name: "RESET_PASSWORD",
        componentClass: ViewResetPassword,
        reducerClass: ReducerResetPasswordBack,
        routage: ResetPasswordRoute
    } as unknown as Page<RPassProps>,

    {
        name: "WAITING",
        componentClass: ViewWaiting,
        reducerClass: ReducerWaiting,
        routage: WaitingViewRoute
    } as unknown as Page<WaitingProps>
];
