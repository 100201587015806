import ServerCore from '../server/ServerCore';
import ServerManagerUI from '../server/ServerManagerUI';
import StoreManager, { StoreAction } from '../redux/StoreManager';
import RouterView, { RouterViewProps } from '../views/router/RouterView';
import * as ErrorCode from '../services/ErrorCode';
import ControllerUser, { UserMetadata } from './ControllerUser';
import * as BrowserCookies from 'browser-cookies';
import ENV from '../env';
import ControllerFile from './ControllerFile';

const COOKIE_NAME = 'FASTBRICK';

export interface CookieInfos {
    token: string;
    email: string;
}

export default class ControllerMain {

    private _serverCore!: ServerCore;
    public serverPromise: Promise<void>;
    public libraryServerPromise!: Promise<void>;
    serverManagerUI!: ServerManagerUI;

    private storeManager: StoreManager;

    view: RouterView | null;

    userMetadata: UserMetadata | undefined;

    //Controllers
    controllerUser: ControllerUser;
    controllerFile: ControllerFile;

    //Is user an admin
    isAdmin: boolean = false;

    constructor() {

        this.view = null;

        this.storeManager = new StoreManager(this);

        const cookieInfos = this.getCookieInfos();

        if (cookieInfos) {
            this.userMetadata = cookieInfos as UserMetadata;
            this.isAdmin = cookieInfos.email === "Edinstitut";
        }


        this.serverPromise = this.initialiseServerConnection();

        this.controllerUser = new ControllerUser(this);
        this.controllerFile = new ControllerFile(this);

    }

    async initialiseServerConnection() {
        //init database connection
        this.serverCore = new ServerCore(this);
        this.serverManagerUI = new ServerManagerUI(this.serverCore);
        await this.serverCore.init();
        await this.serverManagerUI.init();
    }

    start(): React.ReactElement<RouterViewProps> {
        return this.storeManager.generateView();
    }

    private getCookieInfos(): CookieInfos | undefined {
        const cookie = BrowserCookies.get(COOKIE_NAME);

        if (cookie) {
            return JSON.parse(cookie);
        }

        return undefined;
    }

    createUserCookie(token: string, expirationDate: Date, email: string): CookieInfos {

        const cookie = {
            token: Array.isArray(token) ? token[0] : token,
            email
        } as CookieInfos;

        BrowserCookies.set(COOKIE_NAME, JSON.stringify(cookie), { expires: expirationDate, samesite: 'Strict' });
        return cookie;
    }

    public get serverCore() {
        return this._serverCore;
    }

    public set serverCore(sc: ServerCore) {
        this._serverCore = sc;
    }

    dispatch(action: StoreAction) {
        return this.storeManager.dispatch(action);
    }

    redirect(path: string) {
        this.storeManager.redirect(path);
    }

    getCurrentPage() {
        this.storeManager.getCurrentPage();
    }

    refreshWindow(path: string) {
        window.location.pathname = path;
    }

    logout() {
        if (this)
            this.dispatch({
                type: 'LOGIN_LOGOUT',
            });
        // this.userMetadata = undefined;
        // this.library.generateUserMetadata('', {role: Role.DESACTIVE, abilities: []});
    }

    catchError(error: any) {
        if (error === ErrorCode.General.TOKEN_EXPIRED) {
            console.log('Expired token', this);
            // this.dispatch({
            //     type: 'LOGIN_LOGOUT'
            // });

            this.logout();
        } else if (error === ErrorCode.Licence.LICENCE_NOTFOUND) {
            // TODO : display message & logout
            console.log('Licence not found', this);
            this.logout();
        } else if (error === ErrorCode.Role.MAUVAIS_DROITS) {
            console.log('Mauvais droits');
        } else console.log('Error ', error);
    }

}
