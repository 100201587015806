import {Action, Dispatch} from "redux";
import {History} from "history";
import ControllerMain from '../controllers/ControllerMain';

export abstract class MyReducer<S, A extends Action> {

    protected readonly controllerMain: ControllerMain;
    protected readonly dispatch: Dispatch<A>;
    protected readonly history: History;

    constructor(controllerMain: ControllerMain, dispatch: Dispatch<A>, history: History) {
        this.controllerMain = controllerMain;
        this.dispatch = dispatch;
        this.history = history;
    }

    reduce(state: S | undefined, action: A): S {
        // const { state, action } = this.defineReduceParams(storeState, mainAction);
        // this.onReduce(state, action);
        return this.onReduce(state, action);
    }

    // protected abstract defineReduceParams(storeState: MS, mainAction: MA): { state: S; action: A; };

    protected abstract onReduce(state: S | undefined, action: A): S;

    abstract getInitialState(): S;
}
