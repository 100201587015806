import {StoreAction} from "../StoreManager";
import {MyReducer} from "../MyReducer";
import {Action, combineReducers, Dispatch} from "redux";
import {History} from "history";
import {connectRouter, LOCATION_CHANGE, LocationChangeAction, RouterState} from "connected-react-router";
import ControllerMain from '../../controllers/ControllerMain';
import { RouterViewProps } from '../../views/router/RouterView';
import { PageProps, ListPages, Page } from '../../views/Page';


export type PopupType = {
    origin: 'displayPopupFromRight', // Describe origin to apply css
    componentName: '' | string; // Describe which popup component to display (Popup, PopupExpr, ...)
}

export interface ActionDisplayPopup extends Action<"DISPLAY_POPUP">{
    popup: null | PopupType;
}

export type RouterActions = ActionDisplayPopup;


export default class ReducerRouter extends MyReducer<RouterViewProps, StoreAction> {

    private readonly pageReducers: { [k: string]: MyReducer<PageProps, StoreAction>; };

    constructor(controllerMain: ControllerMain, dispatch: Dispatch<StoreAction>, history: History) {
        super(controllerMain, dispatch, history);
        /**
         * Donne:
         * {
         *     "LIST_PROJECTS": new ListProjectsReducer(),
         *     etc...
         * }
         */
        this.pageReducers = {};
        ListPages.forEach((page: Page<any>) => {

            this.pageReducers[page.name] = new (page.reducerClass)(
                this.controllerMain,
                (action: StoreAction) => this.dispatch<any>(action),
                this.history
            );

        });
    }


    protected onReduce(state: RouterViewProps | undefined, action: StoreAction): RouterViewProps {

        if (!state) {
            state = this.getInitialState();
        }

        //@ts-ignore
        return combineReducers<RouterViewProps>({

            router: (state: RouterState, action: StoreAction) => {

                return connectRouter(this.history)(state, action as LocationChangeAction);
            },

            history: (state: History) => state || null,

            pageProps: (state: PageProps, action: StoreAction) => {

                const {pathname} = this.history.location;

                const page = ListPages.find(page => page.routage.checkPath(pathname));

                if (!page) {
                    return {};
                    // throw new Error('pathname non géré: ' + pathname);
                }

                const {name} = page;

                const reducer = this.pageReducers[name];

                if (action.type === LOCATION_CHANGE) {

                    state = reducer.getInitialState();

                }

                return reducer.reduce(state, action);
            }

        })(state, action);

    }

    getInitialState(): RouterViewProps {

        const {pathname} = this.history.location;

        return {

            router: (undefined as any) as RouterState,

            history: this.history,

            pageProps: (() => {

                const page = ListPages.find((page) => page.routage.checkPath(pathname));

                if (!page) {
                    return {};
                    // throw new Error('pathname non géré: ' + pathname);
                }

                const {name} = page;

                const reducer = this.pageReducers[name];

                return reducer.getInitialState();
            })()
        };
    }

}
