/**
 * Codes d'erreur.
 * Same class as server.
 */
// module ErrorCode {

    export enum General {

        UNHANDLED = 1000,
        REQ_NOT_RECOGNIZED = 1001,
        REQ_NO_LONGER_ACCEPTED = 1002,
        SERVER_NO_RESPONDING = 1003,
        USER_CANNOT_SEND = 1004,
        USER_NOT_LOGGED = 1005,
        TOKEN_WRONG = 1006,
        PARAMETERS_MISSING = 1007,
        TOKEN_EXPIRED = 1008
    }

    export enum User {

        USERNAME_WRONG = 1101,
        PASSWORD_WRONG = 1102,
        USERNAME_EXIST = 1121,
        EMAIL_EXIST = 1122,
        USERS_NOTFOUND = 1131,
        USER_NOT_ACTIVE = 1132,
        USER_EXPIRED = 1133
    }

    export enum Role {

        ROLE_NOTFOUND = 1201,
        MAUVAIS_DROITS = 1202
    }

    export enum Projet {

        ACCES_NONAUTORISE = 1301,
        DEV_PRESENT = 1302,
        DATA_INACTIVE = 1310,
        VIEW_INACTIVE = 1311
    }

    export enum Licence {

        LICENCE_NOTFOUND = 1401,
        LICENCE_EXPIRED = 1402

    }

    export enum File {

        FILE_NOT_SAVED = 1501,
        FILE_ALREADY_EXISTS = 1502,
    }

    export enum Export {
        EXPORT_NOT_FOUND = 1601,
        EXPORT_NOT_ACTIVE = 1602
    }

    export enum APIKey {
        API_KEY_EXPIRED = 1701,
        API_KEY_WRONG = 1702
    }

// }