import { Files } from "../redux/reducers/ReducerWaiting";
import { FileUploadReturn, RecepterFileUpload } from "../server/recepters/RecepterFiles";
import ControllerMain from "./ControllerMain";

export default class ControllerFile {
    private readonly controllerMain: ControllerMain;

    constructor(controllerMain: ControllerMain) {
        this.controllerMain = controllerMain;
    }


    async uploadFile(file: File, fileName: Files, onSuccess: (lines?: number) => void, onError: () => void) {
        if (!this.controllerMain.serverManagerUI || !this.controllerMain.serverManagerUI.recepters) {
            await this.controllerMain.serverPromise;
        }

        if (this.controllerMain.userMetadata && this.controllerMain.serverManagerUI) {
            return await this.controllerMain.serverManagerUI.get<RecepterFileUpload>(RecepterFileUpload)
                .send({
                    token: this.controllerMain.userMetadata.token,
                    fileName: fileName,
                    file: file
                })
                .then((data: FileUploadReturn) => {

                    onSuccess(data.linesNumber);

                    return data; 
                })
                .catch((error: any) => {
                    onError();
                    this.controllerMain.catchError(error);
                });
        }
    }
}